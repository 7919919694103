.herosection {
  height: 100%;
  position: relative;
  padding: 150px 0;
  @include media("screen", "md") {
    & {
      padding: 80px 0;
    }
  }
  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background: $dark;
    opacity: 0.97;
  }
  > div {
    position: relative;
    z-index: 1;
    height: inherit;
  }
  .hero-textcontainer {
    .greeting-text {
      @include typography(15px, #dddcdc, 500, normal);
      text-transform: uppercase;
      @include media("screen", "sm") {
        & {
          font-size: 13px;
        }
      }
    }
    h3 {
      @include typography(60px, #fff, 600, normal);
      @include media("screen", "sm") {
        & {
          font-size: 35px;
        }
      }
    }
    p {
      @include typography(18px, #dddcdc, 400, 25px);
      @include media("screen", "sm") {
        & {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    > .create-btn {
      margin-top: 25px;
      position: relative;
      overflow: hidden;
      span {
        transition: all 0.3s ease-out;
      }
      &::after {
        content: "\f061";
        font-size: 16px;
        font-weight: 900;
        position: absolute;
        left: 0;
        opacity: 0;
        transition: all 0.3s ease;
        font-family: "Font Awesome 5 Free";
      }
      &:hover {
        &::after {
          opacity: 1;
          left: 50%;
          transform: translateX(-50%);
        }
        span {
          color: $primary;
        }
      }
    }
  }
  .image-container {
    padding-left: 35px;
    height: 450px;
    @include media("screen", "md") {
      & {
        width: 100%;
        padding-left: 0;
        height: 350px;
        padding-top: 15px;
      }
    }
    img {

      margin-left: auto;
      width: 80%;
      @include media("screen", "md") {
        & {
          width: 100%;
          margin-left: 0;
        }
      }
      border-radius: 24px;
      height: 100%;
      object-fit: cover;
    }
  }
}
