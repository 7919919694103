.navbar-container {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  .site-logo {
    padding: 20px 0;
    img {
      width: 150px;
    }
  }

  .icons-container {
    a {
      display: block;
    }
    svg {
      font-size: 18px;
      margin-right: 10px;
      color: inherit;
      animation: fade-in 0.7s ease forwards;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navbaritems-container {
  position: fixed;
  bottom: 35px;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);
  ul {
    border-radius: 50px;
    padding: 7px 10px;
    backdrop-filter: blur(2px);
    background-color: rgb(0 223 233 / 62%);
    transition: all 0.3s ease;
    li {
      &:hover {
        a {
          border-radius: 35px;
          background-color: $dark;
        }
        .bottom-nav-img {
          svg {
            color: #fff;
          }
        }
        span {
          margin-left: 10px;
          font-size: 14px;
          white-space: nowrap;
          opacity: 1;
        }
      }
      a {
        padding: 7px 13px;
        display: flex;
        align-items: center;
        position: relative;
        .bottom-nav-img {
          svg {
            transition: all 0.3s ease;
            height: 20px;
            width: 20px;
            color: $dark;
          }
        }
        span {
          color: #fff;
          font-size: 0;
          opacity: 0;
          transition: all 0.53s ease;
          // white-space: pre;
          // padding: 7px 11px;
          // color: #fff;
          // background-color: #222;
          // position: absolute;
          // opacity: 0;
          // pointer-events: none;
          // top: -15px;
          // left: 0;
          // z-index: 5;
          // font-size: 0;
          // user-select: none;
        }
      }
    }
  }
}

.socialitems-container {
  ul {
    li {
      &:hover {
        a {
          svg {
            color: $primary;
          }
        }
      }
      a {
        svg {
          transition: all 0.3s ease;
          width: 18px;
          color: #fff;
          height: 18px;
        }
      }
    }
  }
}
