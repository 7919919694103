$sm: 640px;
$md: 768px;
$lg:1024px;
$xl: 1280px;
$vsm: 480px;
$doublexl :1536px;

@function translate-media-conditions($con) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "sm": "(max-width: #{$sm})",
    "md": "(max-width: #{$md})",
    "xl": "(max-width:#{$xl})",
    "lg": "(max-width:#{$lg})",
    "vsm": "(max-width:#{$vsm})",
    "doublexl": "(max-width: #{$doublexl})",
  );
  @return map-get($condMap, $con);
}

@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-conditions($arg);
  }
  @media #{$query} {
    @content;
  }
}
