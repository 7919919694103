@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yatra+One&display=swap");
@import url("https://use.fontawesome.com/releases/v5.7.0/css/all.css");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Karla", sans-serif;
  background-color: #303841;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Yatra One", cursive;
}

::selection {
  color: #303841;
  background-color: #00ADB5;
}

.zrb-container {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: content-box;
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (max-width: 640px) {
  .zrb-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width:480px) {
  .zrb-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section {
  padding-top: 70px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 640px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section-t {
  padding-top: 70px;
}
@media only screen and (max-width: 640px) {
  .section-t {
    padding-top: 50px;
  }
}

.section-head {
  text-align: center;
  position: relative;
  margin-bottom: 45px;
}
@media only screen and (max-width: 768px) {
  .section-head {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 640px) {
  .section-head {
    margin-bottom: 15px;
  }
}

.section-head h1 {
  text-transform: uppercase;
  font-size: 46px;
  color: #fff;
  font-weight: 500;
  line-height: normal;
}
@media only screen and (max-width: 768px) {
  .section-head h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 640px) {
  .section-head h1 {
    font-size: 32px;
  }
}
.section-head h1 span {
  color: #00ADB5;
}

.cta-btn {
  display: inline-block;
  transition: all 0.3s linear;
}
.cta-btn svg {
  display: inline;
  height: 25px;
  width: 25px;
  vertical-align: middle;
}
.cta-btn span {
  display: inline;
  vertical-align: middle;
}
.cta-btn.cta-outline {
  font-family: "Karla", sans-serif;
  font-size: 15px;
  font-weight: 900;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
  border-color: #00ADB5;
  color: #00ADB5;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: transparent;
}
.cta-btn.cta-outline:hover {
  background-color: #00ADB5;
  color: #fff;
}
.cta-btn.cta-primary {
  font-family: "Karla", sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
  border-color: #00ADB5;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: #00ADB5;
}

.navbar-container {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
}
.navbar-container .site-logo {
  padding: 20px 0;
}
.navbar-container .site-logo img {
  width: 150px;
}
.navbar-container .icons-container a {
  display: block;
}
.navbar-container .icons-container svg {
  font-size: 18px;
  margin-right: 10px;
  color: inherit;
  animation: fade-in 0.7s ease forwards;
}
.navbar-container .icons-container svg:last-child {
  margin-right: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navbaritems-container {
  position: fixed;
  bottom: 35px;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);
}
.navbaritems-container ul {
  border-radius: 50px;
  padding: 7px 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 223, 233, 0.62);
  transition: all 0.3s ease;
}
.navbaritems-container ul li:hover a {
  border-radius: 35px;
  background-color: #303841;
}
.navbaritems-container ul li:hover .bottom-nav-img svg {
  color: #fff;
}
.navbaritems-container ul li:hover span {
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 1;
}
.navbaritems-container ul li a {
  padding: 7px 13px;
  display: flex;
  align-items: center;
  position: relative;
}
.navbaritems-container ul li a .bottom-nav-img svg {
  transition: all 0.3s ease;
  height: 20px;
  width: 20px;
  color: #303841;
}
.navbaritems-container ul li a span {
  color: #fff;
  font-size: 0;
  opacity: 0;
  transition: all 0.53s ease;
}

.socialitems-container ul li:hover a svg {
  color: #00ADB5;
}
.socialitems-container ul li a svg {
  transition: all 0.3s ease;
  width: 18px;
  color: #fff;
  height: 18px;
}

.herosection {
  height: 100%;
  position: relative;
  padding: 150px 0;
}
@media only screen and (max-width: 768px) {
  .herosection {
    padding: 80px 0;
  }
}
.herosection::after {
  content: " ";
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background: #303841;
  opacity: 0.97;
}
.herosection > div {
  position: relative;
  z-index: 1;
  height: inherit;
}
.herosection .hero-textcontainer .greeting-text {
  font-size: 15px;
  color: #dddcdc;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
@media only screen and (max-width: 640px) {
  .herosection .hero-textcontainer .greeting-text {
    font-size: 13px;
  }
}
.herosection .hero-textcontainer h3 {
  font-size: 60px;
  color: #fff;
  font-weight: 600;
  line-height: normal;
}
@media only screen and (max-width: 640px) {
  .herosection .hero-textcontainer h3 {
    font-size: 35px;
  }
}
.herosection .hero-textcontainer p {
  font-size: 18px;
  color: #dddcdc;
  font-weight: 400;
  line-height: 25px;
}
@media only screen and (max-width: 640px) {
  .herosection .hero-textcontainer p {
    font-size: 16px;
    line-height: 22px;
  }
}
.herosection .hero-textcontainer > .create-btn {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
.herosection .hero-textcontainer > .create-btn span {
  transition: all 0.3s ease-out;
}
.herosection .hero-textcontainer > .create-btn::after {
  content: "\f061";
  font-size: 16px;
  font-weight: 900;
  position: absolute;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
  font-family: "Font Awesome 5 Free";
}
.herosection .hero-textcontainer > .create-btn:hover::after {
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
}
.herosection .hero-textcontainer > .create-btn:hover span {
  color: #00ADB5;
}
.herosection .image-container {
  padding-left: 35px;
  height: 450px;
}
@media only screen and (max-width: 768px) {
  .herosection .image-container {
    width: 100%;
    padding-left: 0;
    height: 350px;
    padding-top: 15px;
  }
}
.herosection .image-container img {
  margin-left: auto;
  width: 80%;
  border-radius: 24px;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 768px) {
  .herosection .image-container img {
    width: 100%;
    margin-left: 0;
  }
}

.aboutme-section .aboutme-content {
  padding-right: 35px;
}
@media only screen and (max-width: 768px) {
  .aboutme-section .aboutme-content {
    padding-right: 0;
  }
}
.aboutme-section .aboutme-content h2 {
  font-size: 24px;
  color: #EEEEEE;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 15px;
}
.aboutme-section .aboutme-content p {
  margin-bottom: 15px;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8666666667);
  font-weight: 500;
  line-height: 28px;
}
.aboutme-section .aboutme-content p:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 640px) {
  .aboutme-section .aboutme-content p {
    line-height: 26px;
    font-size: 16px;
    text-align: center;
  }
}
.aboutme-section .experience-content {
  padding-left: 35px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 768px) {
  .aboutme-section .experience-content {
    border: none;
    padding-left: 0;
    padding-top: 25px;
  }
}
.aboutme-section .experience-content h2 {
  font-size: 24px;
  color: #EEEEEE;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 15px;
}
.aboutme-section .experience-content ul li {
  margin-bottom: 15px;
  transition: all 0.3s ease;
  padding: 20px 25px;
  border-radius: 14px;
  background-color: #00ADB5;
}
.aboutme-section .experience-content ul li:hover {
  box-shadow: 0 0 40px rgba(0, 173, 181, 0.368627451);
}
.aboutme-section .experience-content ul li h3 {
  text-transform: uppercase;
  font-size: 20px;
  color: #303841;
  font-weight: 500;
  line-height: 28px;
  font-family: "Karla", sans-serif;
}
.aboutme-section .experience-content ul li h3 span {
  font-family: "Yatra One", cursive;
  margin-right: 5px;
  font-size: 13px;
  color: #303841;
  font-weight: 500;
  line-height: 28px;
}
.aboutme-section .experience-content ul li:last-child {
  margin-bottom: 0;
}
.aboutme-section .experience-content ul li .skillsinvolved {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 10px 0 15px 0;
}
.aboutme-section .experience-content ul li .skillsinvolved p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9333333333);
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  padding: 4px 30px;
  background-color: #303841;
}
.aboutme-section .experience-content ul li .date {
  display: flex;
  align-items: center;
}
.aboutme-section .experience-content ul li .date svg {
  color: #303841;
  width: 19px;
  height: 19px;
  margin-right: 10px;
}
.aboutme-section .experience-content ul li .date > div {
  font-size: 15px;
  color: #303841;
  font-weight: 500;
  line-height: normal;
}
.aboutme-section .experience-content ul li .date .startdate {
  position: relative;
  margin-right: 10px;
}
.aboutme-section .experience-content ul li .date .startdate::after {
  content: "-";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -7px;
}

.eachskills img {
  width: 85px;
}
@media only screen and (max-width: 640px) {
  .eachskills img {
    width: 65px;
  }
}

.contactme-container {
  display: grid;
  column-gap: 25px;
  row-gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media only screen and (max-width: 768px) {
  .contactme-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.contactme-container .contactme-form form {
  display: grid;
}
.contactme-container .contactme-form form label {
  margin-bottom: 15px;
}
.contactme-container .contactme-form form input[type=submit] {
  display: inline-block;
  width: fit-content;
  border: none;
  padding: 12px 18px;
  background-color: #00ADB5;
  outline: none;
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}
.contactme-container .contactme-form form input:not([type=submit]),
.contactme-container .contactme-form form textarea {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #00ADB5;
  padding: 7px 11px;
  color: #fff;
  font-size: 16px;
  font-family: "Karla", sans-serif;
}
.contactme-container .contactme-form form textarea {
  background-color: rgba(0, 173, 181, 0.1215686275);
  resize: vertical;
  height: 250px;
}

.contactme-section .socialitems-container ul {
  justify-content: center;
}