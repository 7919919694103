.aboutme-section {
  .aboutme-content {
    padding-right: 35px;
    @include media("screen", "md") {
      & {
        padding-right: 0;
      }
    }
    h2 {
          @include typography(24px, $secondary, 500, 28px);
          margin-bottom: 15px;
        }
    p {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      @include typography(17px, #fffd, 500, 28px);
      @include media("screen", "sm") {
        & {
          line-height: 26px;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
  .experience-content {
    padding-left: 35px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    @include media("screen", "md") {
      & {
        border: none;
        padding-left: 0;
        padding-top: 25px;
      }
    }
    h2 {
      @include typography(24px, $secondary, 500, 28px);
      margin-bottom: 15px;
    }
    ul {
      li {
        margin-bottom: 15px;
        transition: all 0.3s ease;
        &:hover{
          box-shadow: 0 0 40px #00ADB55E;
        }
        h3 {
          text-transform: uppercase;
          @include typography(20px, $dark, 500, 28px);
          font-family: $karla-font;
          span {
            font-family: $yatra-font;
            margin-right: 5px;
            @include typography(13px, $dark, 500, 28px);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        padding: 20px 25px;
        border-radius: 14px;
        background-color: $primary;
        .skillsinvolved {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          margin: 10px 0 15px 0;
          p {
            @include typography(14px, #fffe, 500, normal);
            border-radius: 8px;
            padding: 4px 30px;
            background-color: $dark;
          }
        }
        .date {
          display: flex;
          align-items: center;
          svg {
            color: $dark;
            width: 19px;
            height: 19px;
            margin-right: 10px;
          }
          > div {
            @include typography(15px, $dark, 500, normal);
          }
          .startdate {
            position: relative;
            margin-right: 10px;
            &::after {
              content: "-";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -7px;
            }
          }
        }
      }
    }
  }
}
