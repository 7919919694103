
@mixin typography(
  $fontsize: 1rem,
  $color: #fff,
  $fontweight: 500,
  $lineheight: normal
) {
  font-size: $fontsize;
  color: $color;
  font-weight: $fontweight;
  line-height: $lineheight;
}


@mixin cta-btn(
    $fontsize:1rem,
    $fontweight:500,
    $padding-x:30px,
    $padding-y:14px,
    $borderWidth:2px,
    $borderRadius:2px,
    $borderColor: $primary,
    $color:$primary,
    $bg-color:transparent
){
    font-family: $karla-font;
    font-size: $fontsize;
    font-weight: $fontweight;
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    padding-left: $padding-x;
    padding-right: $padding-x;
    border-width: $borderWidth;
    border-style: solid;
    border-radius: $borderRadius;
    border-color: $borderColor;
    color: $color;
    text-transform: uppercase;
    text-decoration: none !important;
    background-color: $bg-color;
}