@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yatra+One&display=swap");
@import url("https://use.fontawesome.com/releases/v5.7.0/css/all.css");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: $karla-font;
  background-color: $dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $yatra-font;
}

::selection {
  color: $dark;
  background-color: $primary;
}
.zrb-container {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: content-box;
  padding-left: 40px;
  padding-right: 40px;

  @include media("screen", "sm") {
    & {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @include media("screen", "vsm") {
    & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.section {
  padding-top: 70px;
  padding-bottom: 70px;
  @include media("screen", "sm") {
    & {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}

.section-t {
  padding-top: 70px;
  @include media("screen", "sm") {
    & {
      padding-top: 50px;
    }
  }
}

.section-head {
  text-align: center;
  position: relative;
  margin-bottom: 45px;
  @include media("screen", "md") {
    & {
      margin-bottom: 30px;
    }
  }
  @include media("screen", "sm") {
    & {
      margin-bottom: 15px;
    }
  }
}

.section-head h1 {
  text-transform: uppercase;
  @include typography(46px, #fff, 500, normal);
  @include media("screen", "md") {
    & {
      font-size: 42px;
    }
  }
  @include media("screen", "sm") {
    & {
      font-size: 32px;
    }
  }
  span{
    color: $primary;
  }
}

